import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { CdkTreeModule, NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { UIModule } from '@bannerflow/ui';
import { FeedNode } from 'src/app/models/feed.model';

@Component({
    selector: 'feed-tree-fields',
    imports: [CommonModule, CdkTreeModule, MatButtonModule, MatIconModule, MatCheckboxModule, UIModule],
    templateUrl: './feed-tree-fields.component.html',
    styleUrls: ['./feed-tree-fields.component.scss']
})
export class FeedTreeFieldsComponent implements OnInit {
    @Input({ required: true }) fieldFormControl: FormControl<string>;
    @Input({ required: true }) feedSchemaSource: ArrayDataSource<FeedNode>;
    treeControl = new NestedTreeControl<FeedNode>(node => node.children);
    fieldSelection = new SelectionModel<FeedNode>(false /* multiple */);
    destroyRef = inject(DestroyRef);

    ngOnInit(): void {
        const path = this.fieldFormControl.value; // Only one path can be selected at a time for now
        if (path) {
            // We will not be able to find the node if the path as been
            // manually changed
            this.feedSchemaSource
                .connect()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(nodes => {
                    const preSelectedNode = this.findNodeByPath(path, nodes);
                    if (preSelectedNode) {
                        this.fieldSelection.select(preSelectedNode);
                    }
                });
        }
    }

    hasChild = (_: number, node: FeedNode) => !!node.children && node.children.length > 0;

    toggleNode(node: FeedNode) {
        // should only be able to select one node at a time
        const isSelected = this.fieldSelection.isSelected(node);
        if (isSelected) {
            this.fieldSelection.clear();
            this.fieldFormControl.setValue('');
            return;
        } else {
            this.fieldSelection.select(node);
            this.fieldFormControl.markAsTouched();
            this.fieldFormControl.setValue(node.path);
        }
    }

    private findNodeByPath(path: string, nodes: readonly FeedNode[]): FeedNode | undefined {
        for (const node of nodes) {
            if (node.path === path) {
                return node;
            }
            if (node.children) {
                const found = this.findNodeByPath(path, node.children);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    }
}
