import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { filter, Observable, switchMap, take } from 'rxjs';
import { CatalogService, MetaCatalogResponse } from 'src/app/services/api/catalog.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { PublishCatalogComponent } from 'src/app/views/feed-editor/publish-catalog/publish-catalog.component';

@Component({
    selector: 'publish-meta-category',
    imports: [CommonModule, UIModule, PublishCatalogComponent],
    templateUrl: './publish-meta-category.component.html',
    styles: ''
})
export class PublishMetaCatalogComponent {
    @Input() set feedId(feedId: string) {
        this.catalog$ = this.catalogService.getCatalog(feedId);
    };
    catalog$: Observable<MetaCatalogResponse | null>;
    private readonly mainFeedService = inject(MainFeedService);
    private readonly catalogService = inject(CatalogService);

    onUpdateCatalogClicked(catalog: MetaCatalogResponse): void {
        this.mainFeedService.triggerUpdate(catalog.feedId)
            .pipe(
                take(1)
            )
            .subscribe();
    }

    catalogCreated() {
        this.catalog$ = this.catalog$.pipe(
            take(1),
            filter(catalog => catalog !== null),
            switchMap(catalog => this.catalogService.getCatalog(catalog.feedId))
        );
    }
}
