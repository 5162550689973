import { AppEnvironment } from './environment.model';

export const environment: AppEnvironment = {
    stage: 'sandbox',
    buildVersion: 'b65ba3438870e48ca8a9ec3eccce271eabc86244',
    origins: {
        feedsFearsomeProcessor: 'https://sandbox-api.bannerflow.com/feeds-fearsome-processor',
        feedsSupremeCommander: 'https://sandbox-api.bannerflow.com/feeds-supreme-commander',
        feedsSocializingMaster: 'https://sandbox-api.bannerflow.com/feeds-socializing-master',
        acg: 'https://sandbox-api.bannerflow.com/acg',
        feedsDeliveryMaster: 'https://sandbox-api.bannerflow.com/feeds-delivery-master',
        listService: 'https://sandbox-api.bannerflow.com/list-service',
        sapi: 'https://sandbox-api.bannerflow.com/studio/api',
        brandManager: 'https://sandbox-api.bannerflow.com/brand-manager',
        accountAccessService: 'https://sandbox-api.bannerflow.com/account-access',
        socialAccountService: 'https://sandbox-sas.bannerflow.com',
        bfc: 'https://sandbox-home.bannerflow.com',
        creativePreviewUrl: 'https://sandbox-api.bannerflow.com/preview',
        studio: 'https://sandbox-studio.bannerflow.com',
        notificationServiceApi: 'https://sandbox-api.bannerflow.com/notification-service/api',
        notificationServiceHub: 'https://sandbox-api.bannerflow.com/notification-service/hub',
        featureFlagService: 'https://bf-feature-flags.azurewebsites.net/api/frontend'
    },
    auth: {
        clientId: 'pD2aOQUrR5iPSLua1T9PqWIRvsHZbVgb',
        domain: 'https://sandbox-login.bannerflow.com',
        audience: 'https://bannerflow.com/resources/'
    },
    featureFlags: {
        clientKey: '*:development.ba00ae0e5481272a9f10258d046a3fd7ad063b806ae95abc428a1e68'
    }
};
