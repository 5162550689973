<div class="tw-mb-10">
    @if (pageInfo$ | async; as pageInfo) {
        <div class="tw-flex tw-justify-end tw-items-center tw-gap-1 tw-relative tw-top-11">
            <ui-button
                (click)="previous(pageInfo.currentPage)"
                ui-theme="minimal"
                [svgIcon]="'arrow-left'"
                [disabled]="pageInfo.currentPage === 1"></ui-button>
            <p>{{ pageInfo.currentPage }} / {{ pageInfo.totalCards }}</p>
            <ui-button
                (click)="next(pageInfo.currentPage)"
                ui-theme="minimal"
                [svgIcon]="'arrow-right'"
                [disabled]="pageInfo.currentPage === pageInfo.totalCards"></ui-button>
        </div>

        <div class="tw-flex tw-flex-row tw-gap-8">
            @for (creative of pageInfo.activeCard.creatives; track creative.creativeId) {
                <div [ngStyle]="{ width: creative.size.width }">
                    <div class="tw-text-xl tw-mb-6">Creative</div>
                    @if (creative.creativeId !== '' && creative.creativeSetId !== '') {
                        <div
                            class="tw-border-solid tw-border-ui-grey-89 tw-border"
                            [ngStyle]="{
                                width: creative.size.width,
                                height: creative.size.height
                            }">
                            <creative-preview
                                [selectedCreative]="{
                                    creativeId: creative.creativeId,
                                    creativeSetId: creative.creativeSetId
                                }"
                                [dynamicPropertyData]="creative.mappings" />
                        </div>
                    } @else {
                        <div
                            class="tw-bg-ui-waterfall tw-h-full tw-w-full"
                            [ngStyle]="{ height: creative.size.height, width: creative.size.width }">
                            <div class="tw-h-full tw-flex tw-items-center tw-justify-between tw-flex-col tw-py-8">
                                <div class="tw-flex-1 tw-flex tw-items-center">
                                    <div class="tw-bg-ui-color-surface-dropzone tw-p-4">
                                        <ui-svg-icon
                                            class="icon"
                                            icon="creative-set"
                                            fill="#1B75DD" />
                                    </div>
                                </div>
                                <ui-button
                                    class="tw-mt-4"
                                    text="CONNECT CREATIVE"
                                    svgIcon="creative-set"
                                    (click)="connectCreative()"></ui-button>
                            </div>
                        </div>
                    }
                </div>
            }
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-8">
                <div class="tw-text-xl tw-mb-6">Fields</div>
                @for (item of pageInfo.activeCard.data | keyvalue; track item.key; let lastItem = $last) {
                    <div class="grid-3-col text tw-pb-1">
                        <div>{{ item.key }}</div>
                        <preview-value
                            [previewValue]="item.value !== null ? item.value.toString() : ''"></preview-value>
                    </div>
                    @if (!lastItem) {
                        <div class="tw-w-full tw-h-[1px] tw-bg-ui-grey-84"></div>
                    }
                }
            </div>
        </div>
    }
</div>
