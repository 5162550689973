import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UIButtonComponent } from '@bannerflow/ui';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { Template, TemplateOptionalField, TemplateOptionalFieldsResponse } from 'src/app/models/templates.model';
import { SocialFieldsService } from 'src/app/services/api/social-fields.service';

@Component({
    selector: 'add-field',
    imports: [CommonModule, UIButtonComponent, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule],
    templateUrl: './add-field.component.html',
    styleUrls: ['./add-field.component.scss']
})
export class AddFieldComponent implements OnInit {
    readonly template = input<Template>({
        // TODO: It will always be a MetaTemplate, so we could remove this
        id: '',
        label: '',
        type: 'MetaTemplate',
        useAI: false
    });
    readonly selectedOptionalFields = input<string[]>([]);
    @Output() addNewField = new EventEmitter<string>();
    @ViewChild('addFieldWrapper') private addFieldWrapper: ElementRef;

    private socialFieldsService = inject(SocialFieldsService);
    socialMediaOptionalFields$: Observable<TemplateOptionalFieldsResponse>;
    selectedFields = new SelectionModel<string>(true, []);

    ngOnInit() {
        const template = this.template();
        if (template.type !== 'DisplayTemplate') {
            this.selectedFields.setSelection(...this.selectedFields.selected, ...this.selectedOptionalFields());
            this.socialMediaOptionalFields$ = combineLatest([
                this.socialFieldsService.getSocialFields(template.id),
                this.selectedFields.changed.pipe(startWith(null))
            ]).pipe(
                map(([fields, _]) => {
                    fields.optionalFields = fields.optionalFields.filter((field: TemplateOptionalField) => {
                        return !this.selectedFields.isSelected(field.label);
                    });
                    return fields;
                })
            );
        }
    }

    addDisplayField(): void {
        this.addNewField.emit();
        this.scrollToBottom();
    }

    addSDAField(selectedField: string): void {
        if (!this.selectedFields.isSelected(selectedField)) {
            this.addNewField.emit(selectedField);
            this.selectedFields.select(selectedField);
            this.scrollToBottom();
        }
    }

    private scrollToBottom(): void {
        this.addFieldWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
}
