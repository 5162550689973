<div class="tw-flex tw-justify-between tw-items-center tw-w-[400px] tw-flex-col">
    <div
        class="tw-border-solid tw-border-ui-grey-89 tw-border"
        [ngStyle]="{
            width: creativePreviewContainerSize().width,
            height: creativePreviewContainerSize().height
        }">
        <creative-preview
            [selectedCreative]="{ creativeId: creative().id, creativeSetId: creative().creativeSetId }"
            [dynamicPropertyData]="this.currentPreviewData()" />
    </div>

    @if (dynamicPropertyData().length > 0) {
        <div class="tw-mt-2 tw-flex tw-justify-between tw-w-[400px]">
            <p class="tw-text-sm tw-text-gray-600 tw-mb-1">Preview other values</p>
            <div class="tw-flex tw-items-center tw-gap-0">
                <ui-button
                    (click)="previous()"
                    ui-theme="minimal"
                    [svgIcon]="'arrow-left'"
                    class="tw-bg-gray-100"
                    [disabled]="currentPage() === 1">
                </ui-button>
                <ui-number-input
                    class="tw-w-8 tw-text-center tw-mx-1"
                    ui-theme="minimal"
                    [min]="1"
                    [max]="dynamicPropertyData().length"
                    (valueChange)="pageChange($event)"
                    [value]="currentPage()" />
                <span class="tw-text-gray-600 tw-mr-1">/{{ dynamicPropertyData().length }}</span>
                <ui-button
                    (click)="next()"
                    ui-theme="minimal"
                    [svgIcon]="'arrow-right'"
                    class="tw-bg-gray-100"
                    [disabled]="currentPage() === dynamicPropertyData().length">
                </ui-button>
            </div>
        </div>
    }
</div>
