@let mainFeedId = (mainFeedId$ | async) || '';

<header-navigation>
    <ui-svg-icon
        slot="start"
        [icon]="'log'"
        (click)="goToBFCListUrl()"></ui-svg-icon>
    <ns-notifications-component
        *ffFeatureFlag="FeatureFlags.NotificationServiceIntegration"
        slot="end" />
</header-navigation>
<ui-body
    [full]="true"
    class="ui-body">
    <div class="tw-pt-3 tw-h-full">
        <mat-horizontal-stepper
            #stepper
            (selectionChange)="stepChanged($event)"
            [linear]="true">
            <ng-template
                matStepperIcon="edit"
                let-index="index">
                {{ index + 1 }}
            </ng-template>
            <!--  Connect feed set source url -->
            <mat-step
                [stepControl]="formGroup.controls.sourceUrl"
                [label]="Stepper.ConnectYourFeed"
                data-testid="connect-your-feed">
                <connect-feed [sourceUrlControl]="formGroup.controls.sourceUrl">
                    <ui-button
                        class="tw-w-full"
                        type="default"
                        svgIcon="feeds"
                        (click)="stepper.next()"
                        [disabled]="!formGroup.controls.sourceUrl.valid"
                        data-testid="connect-feed"
                        text="CONNECT"></ui-button>
                </connect-feed>
            </mat-step>
            <!-- / Connect feed set source url -->

            <!-- Select template set template name and templateId -->
            <mat-step
                [stepControl]="formGroup.controls.template"
                [label]="Stepper.SelectTemplate">
                <templates
                    class="tw-pb-25"
                    [templateForm]="formGroup.controls.template" />
                <bottom-navigation class="tw-flex tw-justify-end">
                    <div class="tw-flex tw-flex-row tw-gap-6 tw-pr-5">
                        <ui-button
                            [border]="false"
                            text="SET UP MANUALLY"
                            data-testid="button-set-up-manually"
                            icon="folder"
                            [disabled]="!formGroup.controls.template.valid"
                            (click)="stepper.next()" />
                    </div>
                </bottom-navigation>
            </mat-step>
            <!-- / Select template set template name and templateId -->

            <mat-step [label]="Stepper.SelectFields">
                <ng-template matStepContent>
                    <div class="tw-mx-20 tw-pb-25">
                        <select-fields [feedForm]="formGroup" />
                        <bottom-navigation>
                            <div class="tw-flex tw-gap-6 tw-pr-5">
                                @if (formGroup.controls.blueprintFields.invalid) {
                                    <p class="tw-text-ui-warning">
                                        <ui-svg-icon
                                            [icon]="'warning-l-negative'"
                                            class="info-fields" />Connect all fields before proceeding
                                    </p>
                                }

                                <ui-button
                                    type="primary"
                                    [loading]="loading()"
                                    [text]="mainFeedId === '' ? 'Next' : 'Update'"
                                    [disabled]="formGroup.controls.blueprintFields.invalid"
                                    (click)="saveBlueprint(mainFeedId)" />
                            </div>
                        </bottom-navigation>
                    </div>
                </ng-template>
            </mat-step>

            @if (showSelectCreativeStep$ | async) {
                <mat-step [label]="Stepper.ConnectCreative">
                    <ng-template matStepContent>
                        <div class="tw-pb-25">
                            <connect-creative [blueprintFields]="formGroup.controls.blueprintFields" />
                            <bottom-navigation>
                                <div class="tw-flex tw-gap-6 tw-pr-5 tw-grow-1 tw-justify-end">
                                    <p class="tw-text-ui-primary">
                                        <ui-svg-icon
                                            [icon]="'info-l'"
                                            class="info" />This step can be skipped and completed later
                                    </p>
                                    <ui-button
                                        type="primary"
                                        text="CUSTOMIZE FEED"
                                        [loading]="loading()"
                                        (click)="saveCreativeCards()"></ui-button>
                                </div>
                            </bottom-navigation>
                        </div>
                    </ng-template>
                </mat-step>
            }
            <mat-step [label]="Stepper.CustomiseYourFeed">
                <ng-template matStepContent>
                    <customise-feed
                        [filterGroups]="formGroup.controls.filterGroups"
                        [isSaved]="isFiltersSaved()" />
                    @if (!isFiltersSaved()) {
                        <bottom-navigation class="tw-z-1001 tw-fixed">
                            <div class="tw-flex tw-flex-grow tw-pl-5">
                                <ui-button
                                    type="default"
                                    text="BACK TO FIELD SELECTION"
                                    (click)="stepper.previous()" />
                            </div>
                            <div class="tw-flex tw-gap-3">
                                <p class="tw-text-ui-primary">
                                    <ui-svg-icon
                                        icon="info-l"
                                        class="info-customize" />This step can be skipped and completed later
                                </p>
                                <ui-button
                                    type="primary"
                                    [attr.data-testid]="'button-save-filters'"
                                    text="SAVE MY FEED"
                                    [loading]="loading()"
                                    (click)="saveFilters()" />
                            </div>
                        </bottom-navigation>
                    }
                </ng-template>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</ui-body>
