<mat-form-field
    class="tw-w-full"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="isOpen = !isOpen">
    <mat-label>{{ fieldName() }}</mat-label>
    <mat-chip-grid #chipGrid>
        @if (pathFieldControl().value !== '') {
            <mat-chip-row
                (removed)="removeField()"
                (click)="openFieldEditor($event)"
                color="primary"
                highlighted
                [value]="pathFieldControl().value"
                class="chip-row">
                {{ pathFieldControl().value }}
                <button
                    matChipRemove
                    aria-label="'remove ' + keyword"
                    class="chip-btn">
                    <mat-icon class="chip-icon">close</mat-icon>
                </button>
            </mat-chip-row>
        }
        <input
            placeholder=""
            [matChipInputFor]="chipGrid"
            readonly
            (matChipInputTokenEnd)="add($event)"
             />
        <span
            class="icon-btn"
            matSuffix
            ><svg
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                focusable="false"
                aria-hidden="true"
                fill="#737373">
                <path
                    d="M7 10l5 5 5-5z"
                    class="ng-tns-c1771602899-9"></path>
            </svg>
        </span>
    </mat-chip-grid>
</mat-form-field>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
    [cdkConnectedOverlayMinWidth]="'450px'"
    [cdkConnectedOverlayHeight]="'500px'"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayViewportMargin]="10"
    cdkConnectedOverlayPush="true"
    [cdkConnectedOverlayViewportMargin]="20"
    [cdkConnectedOverlayPositions]="[
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -50
        }
    ]"
    (overlayOutsideClick)="openSelect($event)">
    <div class="tw-overscroll-x-none tw-w-full tw-rounded-md ui-scrollbar">
        <ui-panel>
            <div
                panel-header
                class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-center">
                <div class="tw-text-sm tw-font-semibold tw-pl-6">{{ fieldName() | titlecase }}</div>
                <ui-button
                    class="transparent-btn"
                    type="default"
                    svgIcon="close"
                    [border]="false"
                    (click)="openSelect($event)"></ui-button>
            </div>
            <div class="tw-overflow-y-scroll tw-max-h-[500px] tw-p-4 tw-max tw-overflow-x-hidden">
                @if (feedSchemaSource$ | async; as feedSchemaSource) {
                    <feed-tree-fields
                        [fieldFormControl]="pathFieldControl()"
                        [feedSchemaSource]="feedSchemaSource" />
                } @else {
                    <div class="tw-flex tw-justify-center">
                        <ui-loader
                            [inline]="true"
                            class="tw-p-4"></ui-loader>
                    </div>
                }
            </div>
        </ui-panel>
    </div>
</ng-template>

<ui-dialog
    #fieldEditorDialog="ui-dialog"
    [config]="{ headerText: 'EDIT ' + fieldName(), maxWidth: '80%', padding: 0 }">
    <ng-template ui-dialog-template>
        <path-field-editor
            (closeDialog)="fieldEditorDialog.close()"
            [pathFormControl]="pathFieldControl()"
            [fieldName]="fieldName()"
            [fieldId]="fieldId() || ''" />
    </ng-template>
</ui-dialog>
