import { Component, ContentChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'bottom-navigation',
    imports: [],
    templateUrl: './bottom-navigation.component.html',
    styleUrl: './bottom-navigation.component.scss'
})
export class BottomNavigationComponent {
 
}
