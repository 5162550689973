import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { UIColumnDef, UIModule, UITableDataSource } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { PreviewService } from 'src/app/services/api/preview.service';

interface TablePreviewData {
    columnNames: UIColumnDef[];
    dataSource: UITableDataSource<any>;
}

@Component({
    selector: 'preview-table',
    imports: [CommonModule, UIModule],
    templateUrl: './preview-table.component.html',
    styleUrls: ['./preview-table.component.scss']
})
export class PreviewTableComponent {
    private previewService = inject(PreviewService);
    previewData$: Observable<TablePreviewData>;

    previewData = computed(() => {
        const data = this.previewService.previewData();
        if (!data) return { columnNames: [], dataSource: new UITableDataSource() };

        const columnNames: UIColumnDef[] = data.fields.map(field => ({
            name: field.name,
            columnDef: field.name,
            width: '100%'
        }));

        const dataSource = new UITableDataSource();
        dataSource.setData(data.previewData.data.length > 0 ? data.previewData.data.map(item => item.data) : []);

        return {
            columnNames,
            dataSource
        };
    });
}
