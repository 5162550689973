@let creativeSelectorData = creativeSelectorData$ | async;
@let selectedCreative = selectedCreative$ | async;
@let creativePreviewContainerSize = (creativePreviewContainerSize$ | async) || { width: '300px', height: '300px' };

@if (creativeSelectorData) {
    <div
        class="tw-flex tw-flex-row tw-border-x-0 tw-border-y tw-border-solid tw-border-ui-grey-89 tw-my-10 tw-h-[500px]">
        <div class="tw-w-1/3 tw-overflow-hidden">
            <div
                class="tw-text-sm tw-font-semibold tw-p-4 tw-border-b tw-border-solid tw-border-ui-grey-89 tw-border-x-0 tw-border-t-0">
                Languages
            </div>

            <div class="tw-overflow-y-auto ui-scrollbar tw-h-full pb-2">
                @for (version of creativeSelectorData.versions; track version.id) {
                    <div
                        class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-2 tw-px-4 tw-cursor-pointer tw-h-10"
                        [ngClass]="{ 'tw-bg-ui-waterfall': creativeSelectorData.selection.versionId === version.id }"
                        (click)="selectVersion(version.id)">
                        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                            <ui-flag [culture]="version.cultureCode"></ui-flag>
                            <span>{{ version.name }}</span>
                        </div>
                        <ui-svg-icon icon="arrow-right"></ui-svg-icon>
                    </div>
                }
            </div>
        </div>
        <div class="tw-w-1/3 tw-border-y-0 tw-border-x tw-border-solid tw-border-ui-grey-89 tw-overflow-hidden">
            <div
                class="tw-text-sm tw-font-semibold tw-p-4 tw-border-b tw-border-solid tw-border-ui-grey-89 tw-border-x-0 tw-border-t-0">
                Sizes
            </div>

            <div class="tw-overflow-y-auto ui-scrollbar tw-h-[calc(100%-50px)] tw-pb-2">
                @for (size of creativeSelectorData.sizes; track size.id) {
                    <div
                        class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-2 tw-px-4 tw-cursor-pointer tw-h-10"
                        [ngClass]="{
                            'tw-bg-ui-waterfall': creativeSelectorData.selection.size.id === size.id,
                            'tw-cursor-not-allowed': creativeSelectorData.selection.versionId === ''
                        }"
                        [uiTooltip]="'Please select a language first'"
                        [uiTooltipDisabled]="creativeSelectorData.selection.versionId !== ''"
                        (click)="
                            creativeSelectorData.selection.versionId !== '' &&
                                selectSize(creativeSelectorData.selection, size.id, size.width, size.height)
                        ">
                        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                            <div
                                sizeThumbnail
                                [originalWidth]="size.width"
                                [originalHeight]="size.height"
                                class="tw-bg-ui-grey-86"></div>
                            <div>{{ size.width }} x {{ size.height }}</div>
                        </div>
                        <ui-svg-icon icon="arrow-right"></ui-svg-icon>
                    </div>
                }
            </div>
        </div>
        <div class="tw-w-1/3 tw-flex tw-flex-col">
            <div class="tw-border-b tw-border-solid tw-border-ui-grey-89 tw-border-x-0 tw-border-t-0 tw-p-4">
                <div class="tw-flex tw-justify-between tw-items-center">
                    <span class="tw-text-sm tw-font-semibold"> Preview </span>
                    @if (selectedCreative) {
                        <a
                            [href]="selectedCreative.creativeUrl"
                            target="_blank"
                            class="tw-text-blue-600 tw-no-underline tw-flex tw-items-center tw-gap-1 tw-font-semibold">
                            <ui-svg-icon icon="go-to-url"></ui-svg-icon>
                            OPEN IN CREATIVE STUDIO
                        </a>
                    }
                </div>
            </div>

            <div class="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                @if (selectedCreative) {
                    <div
                        class="tw-border-solid tw-border-ui-grey-89 tw-border"
                        [ngStyle]="{
                            width: creativePreviewContainerSize.width,
                            height: creativePreviewContainerSize.height
                        }">
                        <creative-preview
                            [selectedCreative]="{
                                creativeId: selectedCreative.id,
                                creativeSetId: selectedCreative.creativeSetId
                            }" />
                    </div>
                } @else {
                    <div class="tw-bg-ui-waterfall">
                        <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                            <ui-svg-icon
                                icon="creative-set"
                                class="creative-icon" />
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="tw-flex tw-justify-center tw-items-center tw-gap-5 tw-mb-10">
        <ui-button
            type="default"
            (click)="this.closeDialog.emit()"
            text="CANCEL"></ui-button>
        <ui-button
            type="primary"
            [attr.data-testid]="'select-creative-dialog'"
            [disabled]="!creativeSelectorData.selection.size.id || !creativeSelectorData.selection.versionId"
            (click)="selectCreative()"
            text="SELECT"></ui-button>
    </div>
} @else {
    <creative-list (creativeSetChange)="setSelectedCreativeSet($event)"></creative-list>
}
