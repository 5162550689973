import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { UIModule } from '@bannerflow/ui';
import { FeedField } from 'src/app/models/feed.model';
import { ExtractNamesPipe } from 'src/app/pipes/extract-names.pipe';

@Component({
    selector: 'card-summary',
    imports: [CommonModule, MatCardModule, ExtractNamesPipe, UIModule],
    templateUrl: './card-summary.component.html',
    styleUrl: './card-summary.component.scss'
})
export class CardSummaryComponent {
    @Input() fields: FeedField[];
}
