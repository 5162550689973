import { AsyncPipe, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    finalize,
    map,
    switchMap,
    tap
} from 'rxjs';
import { PublishCatalogMetaRequest } from 'src/app/models/catalog.model';
import { Business, Integration } from 'src/app/models/social-integration.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { CatalogService } from 'src/app/services/api/catalog.service';
import { SocialAccountService } from 'src/app/services/api/social-account.service';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'publish-catalog',
    imports: [UIModule, AsyncPipe, NgClass],
    templateUrl: './publish-catalog.component.html',
    styleUrl: './publish-catalog.component.scss'
})
export class PublishCatalogComponent {
    @Output() close = new EventEmitter<void>();
    @Output() catalogPublished = new EventEmitter<void>();
    socialAccountService = inject(SocialAccountService);
    accountAccessService = inject(AccountAccessService);
    catalogService = inject(CatalogService);
    activatedRoute = inject(ActivatedRoute);
    notificationService = inject(UINotificationService);

    selectedIntegration$ = new BehaviorSubject<Integration>({ id: '', name: '', networkId: '', featuresScopes: [] });
    selectedBusiness$ = new BehaviorSubject<Business>({ name: '', id: '' });
    catalogName$ = new BehaviorSubject<string>('');
    integrations$ = this.accountAccessService.user$.pipe(
        switchMap(user => this.socialAccountService.getIntegrations(user))
    );
    accountSettingsUrl$ = this.accountAccessService.user$.pipe(
        map(
            user =>
                `${environment.origins.bfc}/${user.account.slug}/${user.brand.slug}/settings/account/socialintegrations`
        )
    );
    businesses$ = this.selectedIntegration$.pipe(
        filter(Boolean),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        switchMap(integration => {
            if (integration.id) {
                return this.catalogService.getBusinessesByIntegrationId(integration.id);
            }
            return EMPTY;
        })
    );
    feedId$ = this.activatedRoute.params.pipe(map(params => params['id']));
    canPublish$ = combineLatest([this.selectedIntegration$, this.selectedBusiness$, this.catalogName$]).pipe(
        map(([integration, business, catalogName]) => !!integration && !!business && catalogName !== '')
    );
    isLoading = false;

    integrationChanged(integration: Integration) {
        this.selectedIntegration$.next(integration);
    }

    businessChanged(business: Business) {
        this.selectedBusiness$.next(business);
    }

    catalogNameChanged(catalogName: KeyboardEvent) {
        const value = (catalogName.target as HTMLInputElement).value;
        this.catalogName$.next(value);
    }

    publishCatalog(): void {
        combineLatest([this.feedId$, this.selectedIntegration$, this.selectedBusiness$, this.catalogName$])
            .pipe(
                tap(() => (this.isLoading = true)),
                map(([feedId, integration, business, catalogName]) => ({
                    feedId,
                    integrationId: integration.id,
                    businessId: business.id,
                    name: catalogName
                })),
                switchMap(({ feedId, integrationId, businessId, name }) => {
                    const catalogRequest: PublishCatalogMetaRequest = {
                        feedId,
                        integrationId,
                        businessId,
                        name
                    };
                    return this.catalogService.createCatalogMeta(catalogRequest);
                }),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: data => {
                    this.catalogPublished.emit();
                    this.close.emit();
                    this.notificationService.open(`Catalog ${data} is published successfully`, {
                        type: 'success',
                        placement: 'top',
                        autoCloseDelay: 2000
                    });
                },
                error: err => {
                    this.errorNotification(err);
                }
            });
    }
    private errorNotification(errorResponse: HttpErrorResponse): void {
        this.notificationService.open('Something went wrong', {
            type: 'error',
            placement: 'top',
            autoCloseDelay: 2000
        });
        throw errorResponse;
    }
}
