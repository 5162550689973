import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'static-text-field',
    standalone: true,
    imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './static-text-field.component.html',
    styleUrl: './static-text-field.component.scss'
})
export class StaticTextFieldComponent {
    fieldName = input.required<string>();
    textFormControl = input.required<FormControl<string>>();
}
