import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, combineLatest, filter, map, shareReplay, Subject, switchMap, take } from 'rxjs';
import { SizeThumbnailDirective } from 'src/app/directives/size-thumbnail.directive';
import { Creative, CreativeItem, Size } from 'src/app/models/creative.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { BrandService } from 'src/app/services/api/brand.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { environment } from 'src/environments/environment';
import { CreativeListComponent } from '../creative-list/creative-list.component';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
import { calculateScaledDimensions } from 'src/app/utils/calculations';

@Component({
    selector: 'creative-selector',
    imports: [CommonModule, UIModule, SizeThumbnailDirective, CreativeListComponent, CreativePreviewComponent],
    templateUrl: './creative-selector.component.html',
    styleUrl: './creative-selector.component.scss'
})
export class CreativeSelectorComponent {
    @Output() selectedCreative = new EventEmitter<Creative>();
    @Output() closeDialog = new EventEmitter<void>();

    studioService = inject(StudioService);
    brandService = inject(BrandService);
    accountAccessService = inject(AccountAccessService);

    private _versionSizeSelection$ = new BehaviorSubject<{ versionId: string; size: Size }>({
        versionId: '',
        size: { id: '', width: 0, height: 0 }
    });
    private _creativeSetId$ = new Subject<string>();
    creativePreviewContainerSize$ = this._versionSizeSelection$
        .asObservable()
        .pipe(
            map((selection: { size: Size }) =>
                calculateScaledDimensions(selection.size.width, selection.size.height, 300)
            )
        );

    creativeSelectorData$ = combineLatest([
        this._creativeSetId$.pipe(
            switchMap(creativeSetId =>
                this.studioService.getCreativesAndVersions(creativeSetId).pipe(
                    map(data => ({
                        ...data,
                        creativeSetId: creativeSetId
                    }))
                )
            )
        ),
        this._versionSizeSelection$,
        this.accountAccessService.user$.pipe(switchMap(user => this.brandService.getBrandById(user.brand.id)))
    ]).pipe(
        map(data => ({
            creativeSetId: data[0].creativeSetId,
            creativeSetName: data[0].name,
            selection: data[1],
            creatives: data[0].creatives,
            versions: data[0].versions.map(version => ({
                ...version,
                cultureCode: data[2].localizations.find(localization => localization.id === version.localization.id)
                    ?.cultureCode
            })),
            sizes: data[0].sizes,
            brandId: data[2].id
        })),
        shareReplay(1)
    );

    selectedCreative$ = this.creativeSelectorData$.pipe(
        map(data => {
            const selectedCreative = data.creatives.find(
                creative =>
                    creative.version.id === data.selection.versionId && creative.size.id === data.selection.size.id
            );

            if (selectedCreative) {
                const { width, height } = data.selection.size;
                const versionId = data.versions.find(version => version.id === selectedCreative.version.id)!.id;
                const versionName = data.versions.find(version => version.id === selectedCreative.version.id)!.name;

                const creativeUrl = `${environment.origins.studio}/brand/${data.brandId}/creativeset/${data.creativeSetId}/editor/${selectedCreative.id}/${selectedCreative.size.id}/${selectedCreative.design.id}?version=${versionId}`;

                return {
                    ...selectedCreative,
                    size: {
                        ...selectedCreative.size,
                        width,
                        height
                    },
                    version: {
                        id: versionId,
                        name: versionName
                    },
                    creativeSetId: data.creativeSetId,
                    creativeSetName: data.creativeSetName,
                    creativeUrl
                };
            }

            return null;
        })
    );

    setSelectedCreativeSet(creativeSet: CreativeItem): void {
        this._creativeSetId$.next(creativeSet.id);
    }

    selectVersion(newVersionId: string) {
        this._versionSizeSelection$.next({ versionId: newVersionId, size: { id: '', width: 0, height: 0 } });
    }

    selectSize(selection: { versionId: string; size: Size }, newSizeId: string, width: number, height: number): void {
        this._versionSizeSelection$.next({
            versionId: selection.versionId,
            size: { id: newSizeId, width, height }
        });
    }

    selectCreative(): void {
        this.selectedCreative$.pipe(filter(Boolean), take(1)).subscribe({
            next: creative => {
                this.selectedCreative.emit(creative);
                this.closeDialog.emit();
            },
            error: error => console.error('Error selecting creative', error)
        });
    }
}
