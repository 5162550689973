<div>
    <div
        class="tw-w-[286px] tw-p-4 tw-h-38 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-bg-white">
        <h1>{{ fields.length }}</h1>
        <p>Source fields selected</p>
        <div
            class="tw-truncate"
            [uiTooltip]="fields | extractNames">
            {{ fields | extractNames }}
        </div>
    </div>
</div>
