<div class="tw-sticky tw-top-0 tw-z-10">
    <ui-header [full]="true"
        ><div class="header-content">
            <ng-content select="[slot=start]"></ng-content>

            <div class="tw-flex tw-items-center tw-mr-4">
                @if (environment.stage === 'sandbox') {
                    <sandbox-pr-slots></sandbox-pr-slots>
                }
                <ng-content select="[slot=end]"></ng-content>
            </div></div
    ></ui-header>
</div>
