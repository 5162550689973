import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { environment } from 'src/environments/environment';
import { SandboxPrSlotsComponent } from '../sandbox-pr-slots/sandbox-pr-slots.component';

@Component({
    selector: 'header-navigation',
    imports: [UIModule, SandboxPrSlotsComponent],
    templateUrl: './header-navigation.component.html',
    styleUrl: './header-navigation.component.scss'
})
export class HeaderNavigationComponent {
    environment = environment;
}
