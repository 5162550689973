import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { UIModule } from '@bannerflow/ui';
import { OperationsSummary, OperationsType } from 'src/app/models/operations.model';

@Component({
    selector: 'operations-summary',
    imports: [CommonModule, UIModule, MatCardModule],
    templateUrl: './operations-summary.component.html',
    styleUrl: './operations-summary.component.scss'
})
export class OperationsSummaryComponent {
    @Input() operationsSummary: OperationsSummary[] = [];

    OperationsType = OperationsType
}
