import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IsImagePipe } from 'src/app/pipes/is-image.pipe';

@Component({
    selector: 'preview-value',
    imports: [CommonModule, NgOptimizedImage, IsImagePipe],
    templateUrl: './preview-value.component.html',
    styleUrl: './preview-value.component.scss'
})
export class PreviewValueComponent {
    @Input() previewValue: string;
}
