import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import { combineLatest, map, switchMap } from 'rxjs';
import { FeedFieldType, GenericPathFeedField } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { getPreviewData } from 'src/app/utils/preview-data.helper';
import { validatePath } from 'src/app/utils/validator-helper';
import { PreviewValueComponent } from '../../preview-value/preview-value.component';

@Component({
    selector: 'path-field-editor',
    imports: [
        UIModule,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        OverlayModule,
        PreviewValueComponent
    ],
    templateUrl: './path-field-editor.component.html',
    styleUrl: './path-field-editor.component.scss'
})
export class PathFieldEditorComponent {
    pathFormControl = input.required<FormControl<string>>();
    fieldName = input.required<string>();
    fieldId = input.required<string>();
    closeDialog = output<void>();
    readonly previewService = inject(PreviewService);
    readonly feedSourceService = inject(FeedSourceService);
    newPathFormControl = new FormControl<string>('', { nonNullable: true, validators: validatePath() });
    newSplitPath = signal<string[]>([]);
    inputValues = signal<string[]>([]);
    isOpen = false;

    resultPath = computed(() => {
        const result: string[] = [];
        this.newSplitPath().forEach((item, index) => {
            result.push(item);

            const currentInputValue = this.inputValues()[index];

            if (currentInputValue) {
                result.push(`[${currentInputValue}]`);
            } else if (index !== this.newSplitPath().length - 1) {
                result.push('[]');
            }
        });

        return result.join('');
    });

    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));
    feedSourceUrl$ = this.feedSourceService.feedSourceUrl$.pipe(map(url => url));
    previewData$ = combineLatest([toObservable(this.resultPath), this.feedSourceUrl$]).pipe(
        switchMap(([pathValue, feedSourceUrl]) => {
            const pathString = Array.isArray(pathValue) ? pathValue.join('') : pathValue;

            const pathField: GenericPathFeedField = {
                $type: FeedFieldType.Path,
                id: this.fieldId(),
                name: this.fieldName(),
                required: true,
                path: pathString,
            };

            return getPreviewData(
                this.previewService,
                pathField,
                this.fieldName(),
                feedSourceUrl
            );
        })
    );

    constructor() {
        effect(() => {
            this.newPathFormControl.setValue(this.resultPath());
        });
    }

    ngOnInit() {
        // if path has bracket values, extract them to render in input fields
        this.inputValues.set(this.extractBracketValues(this.pathFormControl().value));

        this.newSplitPath.set(this.splitIndexBrackets(this.pathFormControl().value));
    }

    updateInputValue(value: Event, index: number): void {
        const inputValues = (value.target as HTMLInputElement).value;

        this.inputValues.update(value => {
            const updatedValues = [...value];
            updatedValues[index] = inputValues;
            return updatedValues;
        });
    }

    private extractBracketValues(path: string): string[] {
        const regex = /\[(.*?)\]/g;
        return [...path.matchAll(regex)].map(match => match[1]);
    }

    private splitIndexBrackets(path: string): string[] {
        const cleanedPath = path.replace(/\[.*?\]/g, '');
        const splitPath = cleanedPath.split('.');
        return splitPath.map((item, index) => (index === 0 ? item : '.' + item)).filter(item => item !== '');
    }

    onClickConfirm() {
        if (this.resultPath()) {
            this.pathFormControl().setValue(this.resultPath());

            this.closeDialog.emit();
        }
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }
}
