import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import JmesPathValidator from '@bannerflow/feedsv2-jmespath-validator';

export function validatePath(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const validator = new JmesPathValidator();
        const validatedValue = validator.validatePath(control.value);

        return validatedValue.isValid ? null : { invalidSyntax: true };
    };
}

export function validateExpression(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const validator = new JmesPathValidator();
        const validatedValue = validator.validateExpression(control.value);

        return validatedValue.errors.length > 0 ? { invalidSyntax: true } : null;
    };
}
