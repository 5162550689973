@let previewData = previewData$ | async;

<div class="tw-flex-col tw-justify-center tw-items-center tw-py-10 tw-px-16">
    <p class="tw-text-base">
        Create an expression for the
        <span class="tw-text-blue-600">{{ '"' + fieldName() + '"' }}</span> field yourself
    </p>
    <mat-form-field
        class="tw-w-full tw-h-14 tw-text-sm tw-mb-3"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
        <input
            class="tw-h-15"
            matInput
            placeholder="Enter an expression"
            type="text"
            [formControl]="newExpressionFormControl"
            tabindex="-1" />
        @if (newExpressionFormControl.hasError('invalidSyntax')) {
            <mat-error> Invalid expression syntax </mat-error>
        }
    </mat-form-field>
    <ui-button
        class="tw-my-5"
        type="primary"
        text="Select from feed source"
        (click)="isOpen = !isOpen" />

    <div class="tw-mt-5">
        <h3 class="tw-m-0">Preview</h3>
        <div class="tw-min-h-12 tw-flex tw-items-center tw-text-sm">
            @if (newExpressionFormControl.value) {
                @if (previewData) {
                    <preview-value [previewValue]="previewData"></preview-value>
                } @else {
                    <ui-skeleton-cell class="tw-w-[60%]" />
                }
            } @else {
                <p class="tw-italic tw-text-sm">No preview</p>
            }
        </div>
    </div>
    <div class="tw-flex tw-justify-center tw-gap-5 tw-mb-10 tw-mt-10">
        <ui-button
            type="default"
            text="Cancel"
            [attr.data-testid]="'edit-cancel'"
            (click)="closeDialog.emit()">
        </ui-button>
        <ui-button
            id="interaction-edit-confirm"
            type="primary"
            text="Save"
            [disabled]="!newExpressionFormControl.value || newExpressionFormControl.hasError('invalidSyntax')"
            [attr.data-testid]="'edit-confirm'"
            (click)="onClickConfirm()">
        </ui-button>
    </div>
</div>
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
    [cdkConnectedOverlayMinWidth]="'450px'"
    [cdkConnectedOverlayHeight]="'500px'"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayViewportMargin]="10"
    cdkConnectedOverlayPush="true"
    [cdkConnectedOverlayViewportMargin]="20"
    [cdkConnectedOverlayPositions]="[
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -50
        }
    ]"
    (overlayOutsideClick)="openSelect($event)">
    <div class="tw-overscroll-x-none tw-w-full tw-rounded-md ui-scrollbar">
        <ui-panel>
            <div
                panel-header
                class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-center">
                <div class="tw-text-sm tw-font-semibold tw-pl-6">{{ fieldName() | titlecase }}</div>
                <ui-button
                    class="transparent-btn"
                    type="default"
                    svgIcon="close"
                    [border]="false"
                    (click)="openSelect($event)"></ui-button>
            </div>
            <div class="tw-overflow-y-scroll tw-max-h-[500px] tw-p-4 tw-max tw-overflow-x-hidden">
                @if (feedSchemaSource$ | async; as feedSchemaSource) {
                    <feed-tree-fields
                        [fieldFormControl]="newExpressionFormControl"
                        [feedSchemaSource]="feedSchemaSource" />
                } @else {
                    <div class="tw-flex tw-justify-center">
                        <ui-loader
                            [inline]="true"
                            class="tw-p-4"></ui-loader>
                    </div>
                }
            </div>
        </ui-panel>
    </div>
</ng-template>
