export function calculateScaledDimensions(
    originalWidth: number,
    originalHeight: number,
    maxDimension: number
): { width: string; height: string } {
    const aspectRatio = originalWidth / originalHeight;

    let finalWidth: number;
    let finalHeight: number;

    if (aspectRatio > 1) {
        // Image is wider than tall
        finalWidth = maxDimension;
        finalHeight = maxDimension / aspectRatio;
    } else {
        // Image is taller than wide or square
        finalHeight = maxDimension;
        finalWidth = maxDimension * aspectRatio;
    }

    return {
        width: Math.round(finalWidth) + 'px',
        height: Math.round(finalHeight) + 'px'
    };
}
