@for (control of bluePrintFieldsFormAry().controls; track control; let i = $index) {
    <div
        class="tw-w-full tw-grid tw-auto-rows-auto tw-gap-y-0 tw-py-3"
        [ngClass]="headerTemplateRef ? 'tw-grid-cols-3' : 'tw-grid-cols-2'"
        [class.divider]="i !== (bluePrintFieldsFormAry().controls | keyvalue).length - 1">
        <div class="field-label">
            <div class="tw-flex tw-items-center">
                @if (control.controls.removeable.value) {
                    <ui-svg-icon
                        icon="kebab"
                        class="kebab-menu"
                        [uiDropdownTarget]="optionsDropdown" />
                }
                @if (control.controls.editable.value) {
                    <div class="tw-flex tw-justify-center tw-items-center tw-gap-3">
                        <editable-field [formCtrl]="control.controls.name">
                            <p>{{ control.controls.name.value }}</p>
                        </editable-field>
                    </div>
                } @else {
                    <p>{{ control.controls.name.value }}</p>
                }
            </div>
            <mat-icon
                class="field-icon"
                [class.active]="control.controls.metaData.valid"
                >link</mat-icon
            >
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
            <select-type
                class="tw-w-34"
                [attr.data-testid]="'select-type-' + i"
                [bluePrintFieldTypeControl]="control.controls.$type"
                (fieldTypeChanged)="resetMetaData($event, control.controls.metaData)" />
            @switch (control.controls.$type.value) {
                @case (FeedFieldType.Path) {
                    <path-field
                        class="tw-w-full"
                        [attr.data-testid]="'field-select-' + i"
                        [pathFieldControl]="control.controls.metaData.controls[FeedFieldType.Path].controls.path"
                        [fieldId]="control.controls.id.value"
                        [fieldName]="control.controls.name.value" />
                }
                @case (FeedFieldType.StaticText) {
                    <static-text-field
                        class="tw-flex tw-items-center tw-w-full"
                        [fieldName]="control.controls.name.value"
                        [attr.data-testid]="'field-select-' + i"
                        [textFormControl]="
                            control.controls.metaData.controls[FeedFieldType.StaticText].controls.text
                        " />
                }
                @case (FeedFieldType.Creative) {
                    <creative-field
                        [attr.data-testid]="'field-select-' + i"
                        [creativeGroup]="control.controls.metaData.controls[FeedFieldType.Creative]"
                        [canConnectCreative]="canConnectCreative" />
                }
                @case (FeedFieldType.Expression) {
                    <expression-field
                        class="tw-w-full"
                        [expressionFormControl]="
                            control.controls.metaData.controls[FeedFieldType.Expression].controls.expression
                        "
                        [fieldId]="control.controls.id.value"
                        [fieldName]="control.controls.name.value" />
                }
            }
        </div>
        @if (headerTemplateRef) {
            <div class="tw-flex tw-justify-center tw-items-center">
                <ng-container
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: control.controls.name.value }">
                </ng-container>
            </div>
        }
    </div>
    <ui-dropdown
        theme="default"
        [header]=""
        #optionsDropdown>
        <ui-dropdown-item
            *ngFor="let option of kebabMenuOptions"
            (itemClicked)="onKebabMenuOptionClicked(option, i)">
            {{ option }}
        </ui-dropdown-item>
    </ui-dropdown>
}
