import { Component, inject } from '@angular/core';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';

@Component({
    selector: 'draggable-bottom-sheet',
    imports: [],
    templateUrl: './draggable-bottom-sheet.component.html',
    styleUrl: './draggable-bottom-sheet.component.scss'
})
export class DraggableBottomSheetComponent {
    draggableBottomSheetService = inject(DraggableBottomSheetService);

    dragStart() {
        this.draggableBottomSheetService.startDrag();
    }
}
