import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, signal, Signal } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { IDynamicPropertyData, UIModule } from '@bannerflow/ui';
import { map } from 'rxjs';
import { Creative } from 'src/app/models/creative.model';
import { FeedFieldType } from 'src/app/models/feed.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { CreativeMappingsFormComponent } from 'src/app/shared/default-creative-card/creative-mappings-form/creative-mappings-form.component';
import { environment } from 'src/environments/environment';
import { CreativeDetailsPipe } from '../../pipes/creative-details.pipe';
import { CreativeSelectorComponent } from '../creative-selector/creative-selector.component';
import { PaginatedCreativePreviewComponent } from '../paginated-creative-preview/paginated-creative-preview.component';
import { BaseFieldGroup } from 'src/app/models/feed-form.model';

@Component({
    selector: 'default-creative-card',
    imports: [
        UIModule,
        CommonModule,
        MatInputModule,
        CreativeSelectorComponent,
        AsyncPipe,
        CreativeDetailsPipe,
        CreativeMappingsFormComponent,
        PaginatedCreativePreviewComponent
    ],
    templateUrl: './default-creative-card.component.html',
    styleUrl: './default-creative-card.component.scss'
})
export class DefaultCreativeCardComponent {
    creativeField = input.required<BaseFieldGroup>();

    private readonly studioService = inject(StudioService);
    private readonly accountAccessService = inject(AccountAccessService);
    private readonly previewService = inject(PreviewService);
    private readonly templateBuilderService = inject(TemplateBuilderService);

    selectedCreative = signal<Creative | null>(null);
    brandId$ = this.accountAccessService.user$.pipe(map(user => user.brand.id));

    FeedFieldType = FeedFieldType;

    dynamicPropertiesData: Signal<IDynamicPropertyData[][]> = computed(() => {
        const items = this.previewService.previewData()?.previewData.data;
        if (!items) return [];
        const mappings = items.map(item =>
            item.creatives.flatMap(creative => {
                if (!creative.mappings) return [];
                return Object.entries(creative.mappings).map(([id, value]) => ({
                    id: id,
                    value: value.toString()
                }));
            })
        );

        return mappings || [];
    });

    constructor() {
        effect(() => {
            const creativeSetId =
                this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.creativeSetId.value;
            if (creativeSetId) {
                // if we have creativeId we should fetch it and set it to selectedCreative
                this.studioService.getCreativesAndVersions(creativeSetId).subscribe(creativesData => {
                    const creativeFieldCreativeId =
                        this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.creativeId
                            .value;

                    const creative = creativesData.creatives.find(creative => creative.id === creativeFieldCreativeId);

                    if (!creative) {
                        throw new Error('No creative found');
                    }
                    const creativeSize = creativesData.sizes.find(size => size.id === creative.size.id);

                    const { width, height } = creativeSize || { width: 0, height: 0 };

                    creative.size = { id: creative.size.id, width, height };

                    creative.creativeSetName = creativesData.name;

                    const creativeVersion = creativesData.versions.find(version => version.id === creative.version.id);

                    creative.version.name = creativeVersion?.name || '';

                    creative.creativeSetId = creativeSetId;

                    this.selectedCreative.set(creative);
                });
            }
        });
    }

    openCreative(brandId: string, creative: Creative): void {
        if (creative) {
            const url = `${environment.origins.studio}/brand/${brandId}/creativeset/${creative.creativeSetId}/editor/${creative.id}/${creative.size.id}/${creative.design.id}?version=${creative.version.id}&features=dynamic-content`;
            window.open(url, '_blank');
        }
    }

    onCreativeSelected(creative: Creative): void {
        this.studioService.getDynamicFieldsByCreativeId(creative.id).subscribe(dynamicFields => {
            this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.mappings.clear();
            this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.creativeId.setValue(
                creative.id
            );
            this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.creativeSetId.setValue(
                creative.creativeSetId
            );
            dynamicFields.forEach(dynamicField => {
                this.creativeField().controls.metaData.controls[FeedFieldType.Creative].controls.mappings.push(
                    this.templateBuilderService.createMappingFromCreativeDynamicField({
                        $type: FeedFieldType.Path,
                        dynamicPropertyId: dynamicField.id,
                        dynamicPropertyLabel: dynamicField.label,
                        dynamicPropertyType: dynamicField.type,
                        path: ''
                    })
                );
            });
        });
        this.selectedCreative.set(creative);
    }
}
