import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedField, PreviewData } from '../../models/feed.model';
import { FilterGroup } from '../../models/filters.model';

export interface PreviewDataRequest {
    id?: string;
    source: string;
    fields: FeedField[];
    filters?: FilterGroup[];
}

@Injectable({
    providedIn: 'root'
})
export class PreviewService {
    private http = inject(HttpClient);
    private _previewData = signal<{ previewData: PreviewData; fields: FeedField[] } | undefined>(undefined);
    previewData = this._previewData.asReadonly();

    getPreviewData(request: PreviewDataRequest): Observable<PreviewData> {
        return this.http
            .post<PreviewData>(`${environment.origins.feedsFearsomeProcessor}/Preview`, {
                ...request
            })
            .pipe(tap(previewData => this._previewData.set({ previewData, fields: request.fields })));
    }
}
