import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicPropertyData, UIModule } from '@bannerflow/ui';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { FeedValueObject } from 'src/app/models/feed.model';
import { StudioService } from 'src/app/services/api/studio.service';
import { CreativePreviewComponent } from 'src/app/shared/creative-preview/creative-preview.component';
import { PreviewValueComponent } from 'src/app/shared/preview-value/preview-value.component';
import { calculateScaledDimensions } from 'src/app/utils/calculations';

interface PreviewCarouselData {
    values: { fieldName: string; data: string[] }[];
}

interface PageData {
    currentPage: number;
    activeCard: {
        data: { [key: string]: FeedValueObject };
        creatives: {
            creativeId: string;
            creativeSetId: string;
            mappings: IDynamicPropertyData[];
            size: { width: string; height: string };
        }[];
    };
    totalCards: number;
}

@Component({
    selector: 'preview-carousel',
    imports: [CommonModule, UIModule, PreviewValueComponent, CreativePreviewComponent],
    templateUrl: './preview-carousel.component.html',
    styleUrls: ['./preview-carousel.component.scss']
})
export class PreviewCarouselComponent implements OnInit {
    previewData = input.required<
        {
            data: { [key: string]: FeedValueObject };
            creatives: { creativeId: string; creativeSetId: string; mappings: { [key: string]: string }[] }[];
        }[]
    >();

    router = inject(Router);
    route = inject(ActivatedRoute);
    studioService = inject(StudioService);
    previewCarouselData$: Observable<PreviewCarouselData[]>;
    currentPage$ = new BehaviorSubject<number>(1);
    pageInfo$: Observable<PageData>;

    ngOnInit(): void {
        if (this.previewData()) {
            this.pageInfo$ = this.currentPage$.pipe(
                switchMap(currentPage => {
                    const currentPreviewData = this.previewData()[currentPage - 1];

                    if (currentPreviewData.creatives.some(creative => creative.creativeId !== '')) {
                        const firstCreativeSetId = currentPreviewData.creatives[0].creativeSetId;
                        const firstCreativeId = currentPreviewData.creatives[0].creativeId;

                        return this.studioService.getCreativesAndVersions(firstCreativeSetId).pipe(
                            map(creativeData => {
                                const sizeId = creativeData.creatives.find(creative => creative.id === firstCreativeId)
                                    ?.size.id;
                                const size = creativeData.sizes.find(size => size.id === sizeId);

                                const activeCard = {
                                    ...currentPreviewData,
                                    creatives: currentPreviewData.creatives.map((creative, index) => {
                                        return {
                                            creativeId: creative.creativeId,
                                            creativeSetId: creative.creativeSetId,
                                            mappings: creative.mappings
                                                ? Object.entries(creative.mappings).map(([key, value]) => ({
                                                      id: key,
                                                      value: value.toString()
                                                  }))
                                                : [],
                                            size: calculateScaledDimensions(size?.width || 0, size?.height || 0, 700)
                                        };
                                    })
                                };

                                return {
                                    currentPage,
                                    activeCard,
                                    totalCards: this.previewData().length
                                };
                            })
                        );
                    } else {
                        return of({
                            currentPage,
                            activeCard: {
                                ...currentPreviewData,
                                creatives: [
                                    ...currentPreviewData.creatives.map(creative => ({
                                        ...creative,
                                        size: { width: '700px', height: '700px' },
                                        mappings: []
                                    }))
                                ]
                            },
                            totalCards: this.previewData().length
                        });
                    }
                })
            );
        }
    }

    connectCreative(): void {
        this.router.navigate(['edit'], { relativeTo: this.route, queryParams: { tab: 'creatives' } });
    }

    returnZero() {
        return 0;
    }

    previous(page: number): void {
        this.currentPage$.next(page - 1);
    }

    next(page: number): void {
        this.currentPage$.next(page + 1);
    }
}
