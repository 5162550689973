import { ComponentPortal } from '@angular/cdk/portal';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, inject, signal, ViewChild } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { filter, finalize, map, Observable, switchMap, take, tap } from 'rxjs';
import { BaseFieldGroup, FeedForm } from 'src/app/models/feed-form.model';
import { FeedFieldType, FeedUpdateInterval } from 'src/app/models/feed.model';
import { FilterGroupForm } from 'src/app/models/filters.model';
import { Stepper } from 'src/app/models/stepper.model';
import { DynamicFieldsService } from 'src/app/services/api/dynamic-fields.service';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { BottomSheetPreviewComponent } from 'src/app/shared/bottom-sheet-preview/bottom-sheet-preview.component';
import { HeaderNavigationComponent } from 'src/app/shared/header-navigation/header-navigation.component';
import {
    convertBaseFieldGroupToFeedField,
    convertFilterGroupFormToFilterGroups,
    convertFormCreativeFieldMappingsToCreativeMappings
} from 'src/app/utils/main-feed-helper';
import { environment } from 'src/environments/environment';
import { ConnectCreativeComponent } from './connect-creative/connect-creative.component';
import { ConnectFeedComponent } from './connect-feed/connect-feed.component';
import { CustomiseFeedComponent } from './customise-feed/customise-feed.component';
import { SelectFieldsComponent } from './select-fields/select-fields.component';
import { TemplatesComponent } from './templates/templates.component';
import { NSNotificationsComponent } from '@bannerflow/notification';
import { FFFeatureFlagsDirective } from '@bannerflow/feature-flags';
import { FeatureFlags } from 'src/app/models/feature-flags.model';

@Component({
    selector: 'stepper',
    imports: [
        CommonModule,
        UIModule,
        MatButtonModule,
        MatStepperModule,
        ConnectFeedComponent,
        CustomiseFeedComponent,
        SelectFieldsComponent,
        MatIconModule,
        TemplatesComponent,
        HeaderNavigationComponent,
        ConnectCreativeComponent,
        BottomNavigationComponent,
        NSNotificationsComponent,
        FFFeatureFlagsDirective
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
    @ViewChild('stepper') stepper: MatStepper;
    private route = inject(Router);
    private feedSourceService = inject(FeedSourceService);
    private navigationService = inject(NavigationService);
    private mainfeedService = inject(MainFeedService);
    private fb = inject(NonNullableFormBuilder);
    private uiNotificationService = inject(UINotificationService);
    private dynamicFieldsService = inject(DynamicFieldsService);
    private mainFilterService = inject(MainFilterService);
    private previewService = inject(PreviewService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);

    mainFeedId$ = this.mainfeedService.mainFeedId$;
    showSelectCreativeStep$: Observable<boolean>;
    Stepper = Stepper;
    formGroup: FeedForm;
    loading = signal(false);
    isFiltersSaved = signal(false);
    FeatureFlags = FeatureFlags;

    constructor() {
        this.formGroup = this.fb.group({
            sourceUrl: this.fb.control<string>('', Validators.required),
            template: this.fb.group({
                id: this.fb.control('', Validators.required),
                name: this.fb.control('', Validators.required)
            }),
            blueprintFields: this.fb.array<BaseFieldGroup>([]),
            filterGroups: this.fb.array<FilterGroupForm>([]),
            updateInterval: this.fb.control(FeedUpdateInterval.None)
        });

        this.showSelectCreativeStep$ = this.formGroup.controls.blueprintFields.valueChanges.pipe(
            map(fields => fields.some(field => field.$type && field.$type === FeedFieldType.Creative))
        );

        this.formGroup.controls.sourceUrl.valueChanges.subscribe(value => {
            this.feedSourceService.setFeedSourceUrl(value);
        });

        this.formGroup.valueChanges
            .pipe(
                filter(_ => this.formGroup.valid),
                switchMap(_ => {
                    const fields = convertBaseFieldGroupToFeedField(this.formGroup.controls.blueprintFields);
                    const filters = convertFilterGroupFormToFilterGroups(this.formGroup.controls.filterGroups);

                    return this.previewService.getPreviewData({
                        source: this.formGroup.controls.sourceUrl.value,
                        fields: fields,
                        filters: filters
                    });
                })
            )
            .subscribe();
    }

    navigateToBFCList(url: string): void {
        if ((environment.stage = 'proxy')) {
            this.route.navigate(['list']);
        }

        window.location.href = url;
    }

    stepChanged(event: StepperSelectionEvent): void {
        if (event.selectedStep.label === Stepper.CustomiseYourFeed) {
            const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
            this.draggableBottomSheetService.createBottomSheet(componentPortal, '40vh');
        } else {
            this.draggableBottomSheetService.removeBottomSheet();
        }
    }

    saveBlueprint(mainFeedId?: string): void {
        this.loading.set(true);
        const fields = convertBaseFieldGroupToFeedField(this.formGroup.controls.blueprintFields);
        const url = this.formGroup.controls.sourceUrl.value;
        const name = this.formGroup.controls.template.controls.name.value;
        const templateId = this.formGroup.controls.template.controls.id.value;
        let saveBlueprint: Observable<string>;
        if (mainFeedId) {
            saveBlueprint = this.mainfeedService.updateMainFeed({
                id: mainFeedId,
                name: name,
                sourceUrl: url,
                blueprint: { fields: fields },
                updateInterval: FeedUpdateInterval.None
            });
        } else {
            saveBlueprint = this.mainfeedService.saveMainFeed(url, fields, name, templateId);
        }
        saveBlueprint.subscribe({
            next: data => {
                this.uiNotificationService.open(`Saved ${data}`, {
                    type: 'success',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
                this.loading.set(false);
                this.stepper.next();
            },
            error: error => {
                this.loading.set(false);
                this.uiNotificationService.open(error.message, {
                    type: 'error',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
            }
        });
    }

    saveCreativeCards(): void {
        // We assume there is only one creative field present atm, so we can just take the first one
        const creativeField = this.formGroup.controls.blueprintFields.controls.filter(
            field => field.value.$type === FeedFieldType.Creative
        )[0];

        if (creativeField.controls.metaData.controls[FeedFieldType.Creative].controls.creativeId.value === '') {
            this.stepper.next();
            return;
        }

        this.loading.set(true);

        this.mainFeedId$
            .pipe(
                switchMap(mainFeedId => {
                    return this.dynamicFieldsService.updateCreativeField({
                        fieldId: creativeField.controls.id.value,
                        creativeId:
                            creativeField.controls.metaData.controls[FeedFieldType.Creative].controls.creativeId.value,
                        creativeSetId:
                            creativeField.controls.metaData.controls[FeedFieldType.Creative].controls.creativeSetId
                                .value,
                        mainFeedId: mainFeedId,
                        fields: convertFormCreativeFieldMappingsToCreativeMappings(
                            creativeField.controls.metaData.controls[FeedFieldType.Creative].controls.mappings
                        )
                    });
                }),
                finalize(() => {
                    this.loading.set(false);
                })
            )
            .subscribe({
                next: () => {
                    this.loading.set(false);
                    this.stepper.next();
                    this.uiNotificationService.open('Saved creative and dynamic fields', {
                        type: 'success',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                },
                error: () => {
                    this.loading.set(false);
                    this.uiNotificationService.open('Error saving creative dynamic fields', {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                }
            });
    }

    saveFilters(): void {
        const filters = convertFilterGroupFormToFilterGroups(this.formGroup.controls.filterGroups);
        if (filters.length === 0) {
            this.isFiltersSaved.set(true);
            this.draggableBottomSheetService.removeBottomSheet();
            return;
        }

        this.mainFeedId$
            .pipe(
                tap(_ => this.loading.set(true)),
                switchMap(mainFeedId => {
                    return this.mainFilterService.saveMainFilter(mainFeedId, { filterGroups: filters });
                })
            )
            .subscribe(_ => {
                this.loading.set(false);
                this.isFiltersSaved.set(true);
                this.draggableBottomSheetService.removeBottomSheet();
                this.uiNotificationService.open('Saved successfully', {
                    type: 'success',
                    placement: 'top',
                    autoCloseDelay: 3000
                });
            });
    }

    goToBFCListUrl(): void {
        this.navigationService.navigateToBFCListUrl().pipe(take(1)).subscribe();
    }
}
