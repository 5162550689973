@if (isEditing) {
    <mat-form-field>
        @if (formCtrl) {
            <input
                [attr.data-testid]="'edit-input'"
                [formControl]="formCtrl"
                matInput />
        } @else {
            <input
                [attr.data-testid]="'edit-input'"
                [(ngModel)]="value"
                matInput />
        }

        <div
            matSuffix
            class="input-suffix">
            <ui-button
                svgIcon="checkmark-large"
                class="checkmark"
                [attr.data-testid]="'icon-group-checkmark'"
                ui-theme="small"
                (click)="save()">
            </ui-button>
            <ui-button
                svgIcon="close"
                class="close"
                [attr.data-testid]="'icon-group-close'"
                ui-theme="small"
                (click)="cancel()"></ui-button>
        </div>
    </mat-form-field>
}
@if (!isEditing) {
    <div class="label">
        <ng-content />
        <ui-svg-icon
            icon="edit"
            [ngClass]="isEditing ? 'edit-icon-hide' : 'edit-icon-show'"
            (click)="isEditing = true">
        </ui-svg-icon>
    </div>
}
