import { catchError, map, Observable, of } from 'rxjs';
import { FeedField, FeedFieldType } from '../models/feed.model';
import { PreviewService } from '../services/api/preview.service';

export function getPreviewData(
    previewService: PreviewService,
    feedField: FeedField,
    fieldName: string,
    feedSourceUrl: string
): Observable<string> {
    const previewRequest = {
        source: feedSourceUrl,
        fields: [
            {
                ...feedField,
                ...(feedField.$type === FeedFieldType.Path && { children: [] })  // TODO should be removed when SYN-2054 is done.
            }
        ]
    };

    return previewService.getPreviewData(previewRequest).pipe(
        map(response => {
            const fieldValue = response.data[0].data[fieldName];
            if (!fieldValue) {
                return 'No data found';
            }
            return fieldValue + '';
        }),
        catchError(err => {
            console.error('Error fetching preview data: ', err);
            return of('');
        })
    );
}
