<header-navigation>
    <ui-svg-icon
        slot="start"
        [icon]="'log'"
        (click)="goToBFCListUrl()"></ui-svg-icon>
    <ns-notifications-component
        *ffFeatureFlag="FeatureFlags.NotificationServiceIntegration"
        slot="end" />
</header-navigation>
@if (editableFeed$ | async; as editableFeed) {
    <ui-body
        [full]="true"
        class="ui-body">
        <ui-tabs class="tw-px-12">
            <ui-tab name="Main feed settings">
                <div class="tw-flex tw-flex-col tw-bg-ui-grey-98 tw-gap-4">
                    <div class="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                        @if (editableFeed && editableFeed.mainFeed) {
                            <publish-meta-category [feedId]="editableFeed.mainFeed.id!"></publish-meta-category>
                            <div class="tw-w-[1px] tw-h-[100px] tw-bg-ui-grey-89"></div>
                            <card-summary [fields]="editableFeed.mainFeed.blueprint.fields"></card-summary>
                        }
                    </div>
                    <div class="tw-flex tw-w-full tw-flex-col">
                        @if (editableFeed) {
                            @if (editableFeed.previewData) {
                                <preview-carousel [previewData]="editableFeed.previewData"></preview-carousel>
                            }
                            @if (editableFeed.operationsSummary.length > 0) {
                                <div class="tw-mt-13">
                                    <h1 class="tw-font-normal">Operations</h1>
                                    <operations-summary
                                        [operationsSummary]="editableFeed.operationsSummary"></operations-summary>
                                </div>
                            }
                            <div class="tw-mt-13">
                                <h1 class="tw-font-normal">Settings</h1>
                                <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                                    <div class="tw-flex tw-flex-col tw-gap-2">
                                        <mat-form-field>
                                            <mat-label>Name</mat-label>
                                            <input
                                                matInput
                                                placeholder=""
                                                readonly="true"
                                                [value]="editableFeed.mainFeed.name" />
                                        </mat-form-field>
                                        <ui-button
                                            class="tw-mt-3"
                                            data-testid="button-edit-feed"
                                            svgIcon="edit"
                                            text="Make changes in fields & operations"
                                            (click)="openEditor()"></ui-button>
                                    </div>
                                    <div class="tw-px-2.5">
                                        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                                            <mat-form-field>
                                                <mat-label>Update Interval</mat-label>
                                                <mat-select
                                                    [value]="editableFeed.mainFeed.updateInterval"
                                                    #intervalSelect>
                                                    @if (readableUpdateIntervals$ | async; as intervals) {
                                                        @for (interval of intervals; track interval) {
                                                            <mat-option [value]="interval.value">{{
                                                                interval.label
                                                            }}</mat-option>
                                                        }
                                                    }
                                                </mat-select>
                                            </mat-form-field>
                                            <ui-button
                                                [disabled]="
                                                    editableFeed.mainFeed.updateInterval === intervalSelect.value
                                                "
                                                class="tw-h-10"
                                                type="primary"
                                                (click)="updateInterval(editableFeed.mainFeed, intervalSelect.value)"
                                                text="Update interval"></ui-button>
                                        </div>
                                        <div class="w-full">
                                            <p class="tw-mb-5">
                                                Specify how often the feed should be fetched from the server and
                                                updated. Try to avoid picking an interval with an unnecessary short
                                                timespan.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </ui-tab>
        </ui-tabs>
    </ui-body>
}
