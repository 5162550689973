import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { Observable, Subject, combineLatest, map, of, takeUntil } from 'rxjs';
import { Operator } from 'src/app/models/feed.model';
import { FilterGroup, FilterGroupForm } from 'src/app/models/filters.model';
import { OperatorsService } from 'src/app/services/api/operators.service';

@Component({
    selector: 'filter-summary',
    imports: [CommonModule],
    templateUrl: './filter-summary.component.html',
    styleUrl: './filter-summary.component.scss'
})
export class FilterSummaryComponent implements OnInit {
    filterGroup = input.required<FilterGroupForm>();
    feedName = input<string>('Main feed');
    sentence$: Observable<string>;
    private destroy$ = new Subject<void>();

    private operatorsService = inject(OperatorsService);

    ngOnInit(): void {
        this.sentence$ = combineLatest([
            of(this.filterGroup().getRawValue()),
            this.operatorsService.getAllOperators()
        ]).pipe(
            takeUntil(this.destroy$),
            map(([formValues, filterOperators]) => {
                const filterGroup = {
                    ...formValues,
                    filters: formValues.filters.map(filter => ({ ...filter, field: filter.field.toString() }))
                };
                return this.generateSentence(filterGroup, filterOperators);
            })
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    generateSentence(data: FilterGroup, filterOperators: Operator[]): string {
        const filterType = data.filterType || '';
        const filters = data.filters || [];

        let sentence = `I want my feed to ${filterType.toLowerCase()} from <strong>${this.feedName()}</strong> with `;

        const filterParts = filters.map(filter => {
            const field = `<strong>${Array.isArray(filter.field) ? filter.field.join(', ') : filter.field || ''}</strong>`;
            const operator = `that <strong>${this.getOperatorByLabel(filterOperators, filter.operator)}</strong>`;
            const filterArguments = filter.arguments || [];

            const argument = filterArguments.length > 0 ? `<strong>${filterArguments[0]}</strong>` : '';
            return `${field} field ${operator} "${argument}"`;
        });

        sentence += filterParts.join(' or ');

        return sentence;
    }

    getOperatorByLabel(filterOperators: Operator[], selectedOperator: string): string {
        const operator = filterOperators.find(filterOperator => filterOperator.value === selectedOperator);
        return operator ? operator.label : '';
    }
}
