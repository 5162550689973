import { FilterGroups } from './filters.model';

export interface StudioDynamicFieldBase {
    label: string;
    id: string;
    type: DynamicFieldType;
}

interface BaseCreativeDynamicField {
    dynamicPropertyId: string;
    $type: FeedFieldType; // Discriminator for type checking
    dynamicPropertyLabel: string;
    dynamicPropertyType: DynamicFieldType;
}

export type CreativeDynamicField = PathCreativeField | StaticTextCreativeField | ExpressionCreativeField;
interface StaticTextCreativeField extends BaseCreativeDynamicField {
    $type: FeedFieldType.StaticText;
    text: string;
}

interface PathCreativeField extends BaseCreativeDynamicField {
    $type: FeedFieldType.Path;
    path: string;
}

interface ExpressionCreativeField extends BaseCreativeDynamicField {
    $type: FeedFieldType.Expression;
    expression: string;
}
export interface FeedNode {
    name: string;
    children?: FeedNode[];
    isArray: boolean;
    path: string;
    values: string[];
}

export enum FeedFieldType {
    Path = 'Path',
    StaticText = 'StaticText',
    Creative = 'Creative',
    Expression = 'Expression'
}

export type DynamicFieldType = 'Image' | 'Text';

export type FeedField =
    | GenericPathFeedField
    | GenericStaticTextFeedField
    | GenericCreativeFeedField
    | GenericExpressionFeedField;
export interface GenericFeedField {
    $type: FeedFieldType;
    name: string;
    id: string;
    required: boolean;
}
export interface GenericPathFeedField extends GenericFeedField {
    $type: FeedFieldType.Path;
    path: string;
}
export interface GenericStaticTextFeedField extends GenericFeedField {
    $type: FeedFieldType.StaticText;
    text: string;
}
export interface GenericCreativeFeedField extends GenericFeedField {
    $type: FeedFieldType.Creative;
    creativeSetId: string;
    creativeId: string;
    mappings: CreativeDynamicField[];
}

export interface GenericExpressionFeedField extends GenericFeedField {
    $type: FeedFieldType.Expression;
    expression: string;
}
export interface FeedValueObject {
    label: string;
    value: string | null;
    targetUrl: null | string;
}

interface Mapping {
    [key: string]: string;
}

interface PreviewCreative {
    creativeId: string;
    creativeSetId: string;
    mappings: Mapping[];
}

export interface FeedItem {
    data: {
        [key: string]: FeedValueObject;
    };
    creatives: PreviewCreative[];
}

export interface PreviewData {
    data: FeedItem[];
    total: number;
}

export interface MainFeedName {
    id: string;
    name: string;
}

export interface MainFeed {
    id?: string;
    name: string;
    templateId?: string;
    brandId?: string;
    sourceUrl: string;
    blueprint: { fields: FeedField[] };
    filters?: FilterGroups;
    updateInterval: FeedUpdateInterval;
}

export interface Operator {
    label: string;
    value: DomainFunction;
}

export enum DomainFunction {
    Equal = '==',
    Greater = '>',
    Less = '<',
    GreaterEqual = '>=',
    LessEqual = '<=',
    Between = 'between',

    Contains = 'contains',
    Replace = 'replace',
    ToLower = 'toLower',

    Add = 'add',
    Divide = 'divide',
    Multiply = 'multiply',
    Subtract = 'subtract',

    DateFormat = 'dateFormat',
    DistinctBy = 'distinctBy',
    PriorityBy = 'priorityBy'
}

export enum FeedUpdateInterval {
    None = 'None',
    TwentyMinute = 'TwentyMinute',
    ThirtyMinutes = 'ThirtyMinutes',
    OneHour = 'OneHour',
    ThreeHours = 'ThreeHours',
    SixHours = 'SixHours',
    TwelveHours = 'TwelveHours',
    EveryDay = 'EveryDay',
    EveryWeek = 'EveryWeek'
}

export interface DynamicField {
    id: string;
    label: string;
    type: DynamicFieldType;
}
