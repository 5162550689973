import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/account.model';
import { Integration } from 'src/app/models/social-integration.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocialAccountService {
    private http = inject(HttpClient);

    getIntegrations(user: User): Observable<Integration[]> {
        let headers: { [key: string]: string } | undefined = undefined;

        if (user.isEmployee) {
            headers = {
                'bf-account-id': user.account.id
            };
        }

        return this.http.get<Integration[]>(`${environment.origins.socialAccountService}/api/integrations`, {
            params: { networkId: 'facebook' },
            headers: headers
        });
    }

    getAdAccountByIntegrationId(integrationId: string) {
        return this.http.get(
            `${environment.origins.socialAccountService}/api/integrations/${integrationId}/ad-accounts"`
        );
    }
}
