@let previewData = previewData$ | async;

<div class="tw-flex-col tw-justify-center tw-items-center tw-py-10 tw-px-16">
    <p class="tw-text-base">
        Specify array for the
        <span class="tw-text-blue-600">{{ '"' + fieldName() + '"' }}</span> field yourself
    </p>
    <div
        class="tw-flex tw-items-center tw-w-full tw-h-14 tw-text-sm tw-mb-3 tw-border tw-border-solid tw-pl-4 tw-rounded"
        [ngClass]="{'tw-border-red-500': newPathFormControl.hasError('invalidSyntax')}">
        @if (newSplitPath().length > 0) {
            @for (item of newSplitPath(); track item; let i = $index) {
                @if (i === newSplitPath().length - 1) {
                    <p>{{ item }}</p>
                } @else {
                    <p>
                        {{ item }}<span>[</span
                        ><input
                            class="tw-border-none tw-text-center tw-text-sm"
                            [value]="inputValues()[i]"
                            [size]="inputValues()[i] ? (inputValues()[i].length + 1) : 1"
                            (input)="updateInputValue($event, i)" /><span>]</span>
                    </p>
                }
            }
        }
    </div>
    <div class="tw-h-4">
        @if (newPathFormControl.hasError('invalidSyntax')) {
            <mat-error> Invalid path syntax </mat-error>
        }
    </div>
    <div class="tw-mt-5">
        <h3 class="tw-m-0">Preview</h3>
        <div class="tw-min-h-12 tw-flex tw-items-center tw-text-sm">
            @if (previewData) {
                <preview-value [previewValue]="previewData"></preview-value>
            } @else {
                <p class="tw-italic tw-text-sm">No preview</p>
            }
        </div>
    </div>
    <div class="tw-flex tw-justify-center tw-gap-5 tw-mb-10 tw-mt-10">
        <ui-button
            type="default"
            text="Cancel"
            [attr.data-testid]="'edit-cancel'"
            (click)="closeDialog.emit()">
        </ui-button>
        <ui-button
            id="interaction-edit-confirm"
            type="primary"
            text="Save"
            [attr.data-testid]="'edit-confirm'"
            [disabled]="newPathFormControl.hasError('invalidSyntax')"
            (click)="onClickConfirm()">
        </ui-button>
    </div>
</div>
